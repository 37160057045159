import React, { useState, useEffect } from "react";
import { Select } from "antd";
import schoolIcon from "../../../../images/schoolTSQP.png";
import studentIcon from "../../../../images/studentTSQP.png";
import northIcon from '../../../../images/north.png'
import eastIcon from '../../../../images/east.png'
import southIcon from '../../../../images/south.png'
import middleIcon from '../../../../images/middle.png'
import locationIcon from '../../../../images/locationIcon.png'
import _province from "../../election_map/model/_provinces.json";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

const { Option } = Select;



const AreaTSQP = ({ mainData, dataArea, summaryArea, setFilter, filter, tabvalue,onClickSchoolCard }) => {
  const [provinceSelected, setProvinceSelected] = useState(null)
  const [provinceData, setProvinceData] = useState(null)
  const [provinceSelectedData, setProvinceSelectedData] = useState(null)
  const [schoolData, setSchoolData] = useState(null)
  const [summaryProvinceSelected, setSummaryProvinceSelected] = useState(null)
  const [networkOption, setNetworkOption] = useState([])
  const [provinceOption, setProvinceOption] = useState([])
  const [schoolOption, setSchoolOption] = useState([])
  const [schoolSeleted, setSchoolSelected] = useState(null)

  const regionData = [
    {
      regionName: 'ตะวันออก',
      school: summaryArea.region && summaryArea.region['ตะวันออกเฉียงเหนือ'] ? numberWithCommas(summaryArea.region['ตะวันออกเฉียงเหนือ'].school) : 0,
      student: summaryArea.region && summaryArea.region['ตะวันออกเฉียงเหนือ'] ? numberWithCommas(summaryArea.region['ตะวันออกเฉียงเหนือ'].student) : 0,
      icon: eastIcon,
    },
    {
      regionName: 'กลาง',
      school: summaryArea.region && summaryArea.region['กลาง'] ? numberWithCommas(summaryArea.region['กลาง'].school) : 0,
      student: summaryArea.region && summaryArea.region['กลาง'] ? numberWithCommas(summaryArea.region['กลาง'].student) : 0,
      icon: middleIcon,
    },
    {
      regionName: 'เหนือ',
      school: summaryArea.region && summaryArea.region['เหนือ'] ? numberWithCommas(summaryArea.region['เหนือ'].school) : 0,
      student: summaryArea.region && summaryArea.region['เหนือ'] ? numberWithCommas(summaryArea.region['เหนือ'].student) : 0,
      icon: northIcon,
    },
    {
      regionName: 'ใต้',
      school: summaryArea.region && summaryArea.region['ใต้'] ? numberWithCommas(summaryArea.region['ใต้'].school) : 0,
      student: summaryArea.region && summaryArea.region['ใต้'] ? numberWithCommas(summaryArea.region['ใต้'].student) : 0,
      icon: southIcon,
    },


  ]

  const onChange = (type, value,) => {

    let obj = {}

    if (type === 'province_name') {
      if (value === '' || !value) value = 'ทั้งหมด'

      if (value !== 'ทั้งหมด') {
        obj['view'] = 'province'
      } else {
        obj['view'] = 'country'
      }


    } else {
      if (value === '' || !value) value = 'ทั้งหมด'
    }

    obj[type] = value



    // console.log({...filter,...obj });
    setFilter({ ...filter, ...obj })
  };

  const onSearch = (value) => {

  };

  const onChangeSchool = (value) => {

    if (value === '') value = null
    setSchoolSelected(value)
  };

  const onSearchSchool = (value) => {
    console.log('value,schoolData',value,schoolData)
    if(!value) {
      setSchoolData(dataArea)
    }else {
      let filter = schoolData.filter(item => item.hostname.includes(value))
      setSchoolData(filter)
    }
  };


  const onClickSchool = (item) => {
    onClickSchoolCard(item)
  }


  useEffect(() => {
    let network_option = mainData
    if(filter.generation !== 'ทั้งหมด'){
      network_option = mainData.filter(item => item.generation === filter.generation)
    }
    var result = [];
    network_option.reduce(function (res, value) {
      if (!res[value.network]) {
        res[value.network] = { network: value.network };
        if (value.network) {
          result.push(res[value.network]);
        }
      }
      return res;
    }, {});
    setNetworkOption(result)
  }, [mainData,filter])

  useEffect(() => {

    setProvinceData(summaryArea?.province)

  }, [summaryArea])

  useEffect(() => {

    let tmpProvinceOption = ['ทั้งหมด']


    if (summaryArea?.province) {
      summaryArea?.province.forEach(item => {
        tmpProvinceOption.push(item.province)
      });

      setProvinceOption(tmpProvinceOption)
    }


  }, [summaryArea])

  useEffect(() => {
    setProvinceSelectedData(dataArea)
    setSchoolData(dataArea)

  }, [tabvalue, dataArea])

  useEffect(() => {

    let obj = { student: 0, school: 0 }
    let tmpSchoolOpt = []
    if (provinceSelectedData) {
      provinceSelectedData.forEach(item => {
        obj['student'] += (item.total_kindergarten + item.total_junior + item.total_primary + item.total_high)
        tmpSchoolOpt.push({ text: item.hostname, id: item.hostid })
      });

      obj['school'] = provinceSelectedData.length

    }
    setSummaryProvinceSelected(obj)

    setSchoolOption(tmpSchoolOpt)
  }, [provinceSelectedData])


  useEffect(() => {

    let tmp = []
    if (provinceSelectedData && provinceSelectedData.length > 0) {
      tmp = provinceSelectedData.filter(item => item.hostid === schoolSeleted)
    }


    if (schoolSeleted) {
      setSchoolData(tmp)
    } else {
      tmp = dataArea.filter(item => item.province === filter.province_name)
      setSchoolData(tmp)

    }

  }, [schoolSeleted])

  return (
    <>
      <div className="mt-4">
        <div className="text-xl text-center mb-6">
          โครงการ TSQP ครอบคลุมพื้นที่ดำเนินงานใน{" "}
          <span className="text-[#05559E] font-bold">{summaryArea?.province ? summaryArea?.province.length : 0}</span> จังหวัด <br />{" "}
          มีโรงเรียนที่เข้าร่วมโครงการจำนวน{" "}
          <span className="text-[#05559E] font-bold">{summaryArea?.school ? numberWithCommas(summaryArea?.school.length) : 0}</span> โรงเรียน
          นักเรียนจำนวน <span className="text-[#05559E] font-bold">{summaryArea?.student ? numberWithCommas(summaryArea?.student) : 0}</span>{" "}
          คน
        </div>
        <div className="w-full border-2 border-gray-200 border-solid bg-white p-[20px] mx-auto mb-10 rounded-xl shadow-xl">
          <div className="mb-4 text-xl text-center font-bold ">
            ค้นหาพื้นที่ดำเนินงาน
          </div>
          <div className="grid grid-cols-4 gap-4">
            <div className="col-span-4 md:col-span-1">
              <div className="mb-2 text-[16px]">รุ่น</div>
              <Select
                className="sang-select w-full"
                showSearch
                placeholder=""
                optionFilterProp="children"
                onChange={onChange.bind(this, 'generation')}
                onSearch={onSearch.bind(this, 'generation')}
                allowClear
                value={filter.generation}
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
              >
                <Option value="ทั้งหมด">ทั้งหมด</Option>
                <Option value="1">1 (2562)</Option>
                <Option value="2">2 (2563)</Option>
              </Select>
            </div>
            <div className="col-span-4 md:col-span-1">
              <div className="mb-2 text-[16px]">เครือข่าย</div>
              <Select
                className="sang-select w-full"
                showSearch
                placeholder=""
                optionFilterProp="children"
                onChange={onChange.bind(this, 'network')}
                onSearch={onSearch.bind(this, 'network')}
                allowClear
                value={filter.network}
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
              >
                <Option value="ทั้งหมด">ทั้งหมด</Option>
                {networkOption.map((item, idx) =>
                  <Option value={item.network}>{item.network}</Option>
                )}
              </Select>
            </div>
            <div className="col-span-4 md:col-span-1">
              <div className="mb-2 text-[16px]">สังกัด</div>
              <Select
                className="sang-select w-full"
                showSearch
                placeholder=""
                optionFilterProp="children"
                value={filter.sungkud}
                onChange={onChange.bind(this, 'sungkud')}
                onSearch={onSearch.bind(this, 'sungkud')}
                allowClear
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
              >
                <Option value="ทั้งหมด">ทั้งหมด</Option>
                <Option value="สพฐ.">สพฐ.</Option>
                <Option value="อปท.">อปท.</Option>
                <Option value="สช.">สช.</Option>

              </Select>
            </div>
            <div className="col-span-4 md:col-span-1">
              <div className="mb-2 text-[16px]">จังหวัด</div>
              <Select
                className="sang-select w-full"
                showSearch
                placeholder=""
                optionFilterProp="children"
                onChange={onChange.bind(this, 'province_name')}
                onSearch={onSearch.bind(this, 'province_name')}
                value={filter.province_name}
                allowClear
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
              >
                {provinceOption.map((item, idx) =>
                  <Option value={item}>{item}</Option>
                )}
              </Select>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-1 gap-6">
          <div>
            <div className="grid grid-cols-2 gap-4">
              <div className="col-span-2 md:col-span-1 flex justify-around items-center w-full bg-[#FFFFFF] border-[1px] border-solid p-[20px] mx-auto mb-10 rounded-xl shadow-xl">
                <div>
                  <img src={schoolIcon} width={55} height={55} />
                </div>
                <div className="text-black text-center font-bold">
                  <div className="mb-4">จำนวนโรงเรียน</div>
                  <div className="text-left">
                    <span className="text-xl text-[#171FD4] font-bold">
                      {summaryArea?.school ? numberWithCommas(summaryArea?.school.length) : 0}{" "}
                    </span>{" "}
                    โรงเรียน
                  </div>
                </div>
              </div>
              <div className="col-span-2 md:col-span-1 flex justify-around items-center w-full bg-[#FFFFFF] border-[1px] border-solid p-[20px] mx-auto mb-10 rounded-xl shadow-xl">
                <div>
                  <img src={studentIcon} width={55} height={55} />
                </div>
                <div className="text-black text-center font-bold">
                  <div className="mb-4">จำนวนนักเรียน</div>
                  <div className="text-right">
                    <span className="text-xl text-[#17D457] font-bold">
                      {summaryArea?.student ? numberWithCommas(summaryArea?.student) : 0}{" "}
                    </span>{" "}
                    คน
                  </div>
                </div>
              </div>
            </div>
            {filter.province_name === 'ทั้งหมด' ? (
              <>
                <div className="text-[#048967] text-2xl font-bold mb-2">
                  จำนวนโรงเรียนแบ่งตามภูมิภาค
                </div>
                <div className="w-full bg-[#FFFFFF] border-[1px] border-solid p-[20px] mx-auto mb-2 rounded-xl shadow-xl">
                  <div className="grid grid-cols-3 gap-6 items-center border-b-[1px] border-b-solid p-4">
                    <div className="text-center font-bold">ภูมิภาค</div>
                    <div className="text-center font-bold">จำนวนโรงเรียน</div>
                    <div className="text-center font-bold">จำนวนนักเรียน</div>
                  </div>

                  {regionData.map((item, idx) => (
                    <div className="grid grid-cols-3 gap-6 items-center p-4">
                      <div className="flex justify-center items-center font-bold">
                        <img
                          className="mr-4"
                          src={item.icon}
                          width={45}
                          height={45}
                        />
                        <div>{item.regionName}</div>
                      </div>
                      <div className="text-center font-bold">{item.school}</div>
                      <div className="text-center font-bold">
                        {item.student}
                      </div>
                    </div>
                  ))}
                </div>
              </>
            ) : (
              <>
                <div className="text-[#048967] text-2xl font-bold mb-2">
                  จำนวนโรงเรียนแบ่งตามจังหวัด
                </div>
                <div className="w-full bg-[#FFFFFF] border-[1px] border-solid p-[20px] mx-auto mb-2 rounded-xl shadow-xl">
                  <div className="grid grid-cols-3 gap-6 items-center border-b-[1px] border-b-solid p-4">
                    <div className="text-center font-bold">จังหวัด</div>
                    <div className="text-center font-bold">จำนวนโรงเรียน</div>
                    <div className="text-center font-bold">จำนวนนักเรียน</div>
                  </div>

                  <div className="grid grid-cols-3 gap-6 items-center p-4">
                    <div className="flex justify-center items-center font-bold">
                      {/* <img className="mr-4" src={item.icon} width={45} height={45}/> */}
                      <div>{filter.province_name}</div>
                    </div>
                    <div className="text-center font-bold">{summaryProvinceSelected?.school ? numberWithCommas(summaryProvinceSelected.school) : 0}</div>
                    <div className="text-center font-bold">{summaryProvinceSelected?.student ? numberWithCommas(summaryProvinceSelected.student) : 0}</div>
                  </div>
                </div>
              </>
            )}
          </div>

          {filter.province_name === 'ทั้งหมด' ? (
            <>
              <div className="w-full bg-[#FFFFFF] border-[1px] border-solid p-[20px] mx-auto mb-10 rounded-xl shadow-xl">
                <div className="flex justify-between items-center pb-6 border-b-[2px] border-b-solid">
                  <div className="text-xl text-[#0153AF] font-bold">
                    รายชื่อจังหวัด
                  </div>
                </div>

                <div className="h-[650px] overflow-y-auto flex flex-col gap-6 mt-6">
                  {provinceData && provinceData.map((item, idx) => (
                    <div
                      className="w-full bg-[#F7F9FE] border-[1px] border-solid mx-auto p-2 rounded-xl shadow-xl cursor-pointer hover:border-[#4188F3]"
                      onClick={() => {
                        setFilter({
                          ...filter, view: 'province',
                          province_name: item?.province
                        })
                      }}
                    >
                      <div className="flex justify-between items-center border-b-[1px] border-b-solid p-2">
                        <div className="flex">
                          <img
                            className="w-[50%] mr-4"
                            src={locationIcon}
                            width={20}
                            height={20}
                          />
                          <div className="text-xl font-bold">{item.province}</div>
                        </div>
                        <div className="bg-[#4188F3] border-[1px] border-solid rounded-[10px] p-2">
                          <div className="text-[13px] text-white font-bold">
                            สำรวจข้อมูล
                          </div>
                        </div>
                      </div>
                      <div className="grid grid-cols-2 mt-2 p-2 font-bold">
                        <div className="text-left">จำนวนโรงเรียน TSQP</div>
                        <div className="text-right">จำนวนนักเรียน</div>
                      </div>
                      <div className="grid grid-cols-2 items-center p-2 font-bold text-center">
                        <div className="text-left">
                          <span className="text-xl text-[#048967] font-bold">
                            {numberWithCommas(item.school)}{" "}
                          </span>{" "}
                          โรงเรียน
                        </div>
                        <div className="text-right">
                          <span className="text-xl text-[#048967] font-bold">
                            {numberWithCommas(item.student)}{" "}
                          </span>{" "}
                          คน
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="w-full bg-[#FFFFFF] border-[1px] border-solid p-[20px] mx-auto mb-10 rounded-xl shadow-xl">
                <div className="flex justify-between items-center pb-6 border-b-[2px] border-b-solid">
                  <div className="flex gap-4 justify-between items-center text-xl text-[#0153AF] font-bold">
                    <ArrowBackIcon sx={{ fontSize: 18, color: 'black', cursor: 'pointer' }} onClick={() => {
                      setFilter({
                        ...filter, view: 'country',
                        province_name: 'ทั้งหมด'
                      })
                    }} />
                    รายชื่อโรงเรียน
                  </div>
                  <Select
                    className="sang-select w-[50%]"
                    showSearch
                    placeholder="ค้นหา..."
                    optionFilterProp="children"
                    onChange={onChangeSchool}
                    onSearch={onSearchSchool}
                    allowClear
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                  >
                    {schoolOption && schoolOption.map((item, idx) =>
                      <Option value={item.id}>{item.text}</Option>
                    )}
                  </Select>
                </div>

                <div className="h-[650px] overflow-y-auto flex flex-col gap-6 mt-6">
                  {schoolData?.map((item, idx) => (
                    <div
                      className="w-full bg-[#F7F9FE] border-[1px] border-solid mx-auto p-2 rounded-xl shadow-xl cursor-pointer hover:border-[#4188F3]"
                      onClick={() => onClickSchool(item)}
                    >
                      <div className="flex justify-between items-center border-b-[1px] border-b-solid p-2">
                        <div className="flex w-[60%]">
                          <img
                            className="w-[30px] mr-4"
                            src={locationIcon}
                            width={20}
                            height={20}
                          />
                          <div className="text-xl font-bold">{item.hostname}</div>
                        </div>
                        {/* <div className="bg-[#4188F3] border-[1px] border-solid rounded-[10px] p-2">
                          <div className="text-[13px] text-white font-bold">
                            สำรวจข้อมูล
                          </div>
                        </div> */}
                      </div>
                      <div className="grid grid-cols-2 mt-2 p-2 font-bold">
                        <div className="text-center">จำนวนนักเรียน <span className="text-xl text-[#048967] font-bold"> {(item.total_kindergarten + item.total_junior + item.total_primary + item.total_high)} </span> คน</div>
                        <div className="text-center">สังกัด {item.sungkud}</div>
                      </div>

                    </div>
                  ))}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default AreaTSQP;
