import React, { useState, useEffect, useRef } from 'react';
import { Modal, Divider } from 'antd';
import '../index.css';
import './TSQP.css'
import Layoutas from '../../components/Layout';
import Mapbox from '../../mapbox'
import LegendCard from '../../mapbox/LegendCard'
import Sidetab from '../../components/Sidetab'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SummaryTSQP from './map_tsqp_contents/SummaryTSQP';
import AreaTSQP from './map_tsqp_contents/AreaTSQP';
import _province from "../election_map/model/_provinces.json";
import Calculate from "../../util/calculate";

import marker_obec from '/images/marker/dot-obec.svg'
import marker_bpp from '/images/marker/dot-bpp.svg'
import marker_onab from '/images/marker/dot-onab.svg'

import TumbonAddr from "../../util/TumbonAddr";
import region from "../../util/region";
import api from '../../util/api'
import getProvinceName from '../../util/getProvinceName'

const getSummaryTarget = (data) => {

  let obj = { s_total_v1: 0, t_total_v1: 0, s_total_v2: 0, t_total_v2: 0, total_room_v1: 0, total_room_v2: 0, all_school_v1: 0, all_school_v2: 0 }

  data.forEach(item => {
    if (item.generation === '1') {
      obj['s_total_v1'] += (item.total_kindergarten + item.total_junior + item.total_primary + item.total_high)
      obj['t_total_v1'] += item?.t_total
      obj['total_room_v1'] += item?.total_room ? parseInt(item?.total_room) : 0
      obj['all_school_v1'] += 1


    } else {
      obj['s_total_v2'] += (item.total_kindergarten + item.total_junior + item.total_primary + item.total_high)
      obj['t_total_v2'] += item?.t_total
      obj['total_room_v2'] += item?.total_room ? parseInt(item?.total_room) : 0
      obj['all_school_v2'] += 1
    }
  });

  return obj

}

const getSummarySungkud = (data) => {

  let spt = []
  let apt = []
  let sc = []
  let spt_v2 = []
  let apt_v2 = []
  let sc_v2 = []
  let all_v1 = []
  let all_v2 = []

  data.forEach(item => {

    if (item.generation === '1') {
      if (item.sungkud === 'สพฐ.') spt.push(item)
      if (item.sungkud === 'อปท.') apt.push(item)
      if (item.sungkud === 'สช.') sc.push(item)
      all_v1.push(item)
    } else {
      if (item.sungkud === 'สพฐ.') spt_v2.push(item)
      if (item.sungkud === 'อปท.') apt_v2.push(item)
      if (item.sungkud === 'สช.') sc_v2.push(item)
      all_v2.push(item)
    }


  });




  return { spt_v1: spt, apt_v1: apt, sc_v1: sc, spt_v2: spt_v2, apt_v2: apt_v2, sc_v2: sc_v2, all_v1: all_v1, all_v2: all_v2 }

}

const getSummaryClass = (data) => {

  let obj = { 1: [], 2: [] }

  let attr = ['1', '2']
  attr.forEach(key => {
    let total_kindergarten = 0
    let total_primary = 0
    let total_junior = 0
    let total_high = 0
    data.forEach(item => {
      if (item.generation === key) {
        total_kindergarten += item.total_kindergarten
        total_primary += item.total_primary
        total_junior += item.total_junior
        total_high += item.total_high
      }
    });

    obj[key].push(total_kindergarten)
    obj[key].push(total_primary)
    obj[key].push(total_junior)
    obj[key].push(total_high)
  });


  return obj

}

const getSummaryCCTSungkudChart = (data) => {

  let obj = { 1: { spt: [], apt: [], sc: [] }, 2: { spt: [], apt: [], sc: [] } }

  let attr = ['1', '2']
  attr.forEach(key => {
    let s_total_spt = 0
    let jon_spt = 0
    let exjon_spt = 0
    let s_total_apt = 0
    let jon_apt = 0
    let exjon_apt = 0
    let s_total_sc = 0
    let jon_sc = 0
    let exjon_sc = 0
    data.forEach(item => {
      if (item.generation === key) {
        if (item.sungkud === 'สพฐ.') {
          s_total_spt += (item.total_kindergarten + item.total_junior + item.total_primary + item.total_high)
          jon_spt += (item.jon_f + item.jon_m)
          exjon_spt += (item.exjon_f + item.exjon_m)
        } else if (item.sungkud === 'อปท.') {
          s_total_apt += (item.total_kindergarten + item.total_junior + item.total_primary + item.total_high)
          jon_apt += (item.jon_f + item.jon_m)
          exjon_apt += (item.exjon_f + item.exjon_m)
        }
        else if (item.sungkud === 'สช.') {
          s_total_sc += (item.total_kindergarten + item.total_junior + item.total_primary + item.total_high)
          jon_sc += (item.jon_f + item.jon_m)
          exjon_sc += (item.exjon_f + item.exjon_m)
        }
      }
    });

    obj[key]['spt'].push(s_total_spt)
    obj[key]['spt'].push(jon_spt)
    obj[key]['spt'].push(exjon_spt)
    obj[key]['apt'].push(s_total_apt)
    obj[key]['apt'].push(jon_apt)
    obj[key]['apt'].push(exjon_apt)
    obj[key]['sc'].push(s_total_sc)
    obj[key]['sc'].push(jon_sc)
    obj[key]['sc'].push(exjon_sc)
  });


  return obj

}

const getSummaryAreaHeader = (data) => {
  let obj = { student: 0 }

  data.forEach(item => {
    obj['student'] += (item.total_kindergarten + item.total_junior + item.total_primary + item.total_high)
  });

  obj['school'] = data

  var result = [];
  data.reduce(function (res, value) {
    if (!res[value.province]) {
      res[value.province] = { province: value.province };
      if (value.province) {
        result.push(res[value.province]);
      }
    }
    return res;
  }, {});


  obj['province'] = result



  return obj

}

const FullMap = (props) => {
  const [open, setOpen] = useState(true)
  const [data, setData] = useState([])
  const [dataArea, setDataArea] = useState([])
  const [summaryArea, setSummaryArea] = useState({})
  const [summaryTarget, setSummaryTarget] = useState([])
  const [summarySungkud, setSummarySungkud] = useState({})
  const [summaryClass, setSummaryClass] = useState({})
  const [summaryCCTSungkud, setSummaryCCTSungkud] = useState({})
  const [tabvalue, setTabValue] = React.useState(0);
  const [data_map, setDataMap] = useState([])
  const [initData, setInitData] = useState(null)

  const [border_color] = useState(['#1b5e20', '#388e3c', '#66bb6a', '#94a3b8', '#94a3b8',])
  const is_Click = useRef(false);
  const [filter, setFilter] = React.useState({
    minView: "country",
    view: 'country',
    year: "2565",
    region: "ทั้งหมด",
    province_name: "ทั้งหมด",
    city_name: null,
    tumbon_name: null,
    generation: 'ทั้งหมด',
    network: 'ทั้งหมด',
    network_summary: 'ทั้งหมด',
    sungkud: 'ทั้งหมด',
  });
  const [modalSchool, setModalSchool] = useState({
    visible: false,
    resource: null
  });

  const [legend_data, setLegendData] = useState({
    title: '',
    subtitle: '',
    data: [],
    footer: ''
  })


  const getSummaryArea = (data) => {
    let obj = { student: 0 }

    data.forEach(item => {
      obj['student'] += (item.total_kindergarten + item.total_junior + item.total_primary + item.total_high)
    });

    obj['school'] = data

    var province = [];
    data.reduce(function (res, value) {
      if (!res[value.province]) {
        res[value.province] = { province: value.province, student: 0, teacher: 0, school: 0 };
        if (value.province) {
          province.push(res[value.province]);
        }
      }
      res[value.province].student += (value.total_kindergarten + value.total_junior + value.total_primary + value.total_high) ? (value.total_kindergarten + value.total_junior + value.total_primary + value.total_high) : 0;
      res[value.province].teacher += value.t_total ? value.t_total : 0;
      res[value.province].school += 1;
      return res;
    }, {});

    var cityData = [];

    let provinceFilter = data.filter(x => x.province === filter.province_name)

    provinceFilter.reduce(function (res, value) {
      if (!res[value.district]) {
        res[value.district] = { province: value.province, district: value.district, city_name: value.district, student: 0, teacher: 0, school: 0 };
        if (value.district) {
          cityData.push(res[value.district]);
        }
      }
      res[value.district].student += (value.total_kindergarten + value.total_junior + value.total_primary + value.total_high) ? (value.total_kindergarten + value.total_junior + value.total_primary + value.total_high) : 0;
      res[value.district].teacher += value.t_total ? value.t_total : 0;
      res[value.district].school += 1;
      return res;
    }, {});


    var regionData = [];
    data.reduce(function (res, value) {
      if(!value.region_thai) {
        value.region_thai = region.getRegion(value.province).replace("ภาค","")
      }
      if (!res[value.region_thai]) {
        res[value.region_thai] = { region_thai: value.region_thai, student: 0, teacher: 0, school: 0 };
        if (value.region_thai) {
          regionData.push(res[value.region_thai]);
        }
      }
      res[value.region_thai].student += (value.total_kindergarten + value.total_junior + value.total_primary + value.total_high) ? (value.total_kindergarten + value.total_junior + value.total_primary + value.total_high) : 0;
      res[value.region_thai].teacher += value.t_total ? value.t_total : 0;
      res[value.region_thai].school += 1;
      return res;
    }, {});

    obj['province'] = province
    obj['city'] = cityData
    obj['region'] = {}

    regionData.forEach(item => {
      obj['region'][item.region_thai] = item
    });



    return obj

  }

  useEffect(async () => {
    let res_target_group = await api.getInitData('fullmap-tsqp')
    let is_login = api.isLogin().status
    let res_profile = await (is_login ? api.getProfileDb() : null)
    if (res_profile) {
      if (res_profile?.profile?.roles?.includes("partner_province") && api.getActiveRole() === "partner_province") {
        let pid = res_profile?.profile?.profile?.provinceId
        let tmp = [...new Set([
          ...res_target_group.deepdata.tsqp_2562.filter(item => item.province === getProvinceName(pid)),
          ...res_target_group.deepdata.tsqp_2563.filter(item => item.province === getProvinceName(pid))
        ])]
        setData(tmp)
        setInitData({
          ...res_target_group.deepdata,
          tsqp_2562 : res_target_group.deepdata.tsqp_2562.filter(item => item.province === getProvinceName(pid)),
          tsqp_2563 : res_target_group.deepdata.tsqp_2563.filter(item => item.province === getProvinceName(pid))
        })
        setFilter({ ...filter, minView : "province",view: "province", province_name: getProvinceName(pid) })
      } else {
        let tmp = [...new Set([...res_target_group.deepdata.tsqp_2562, ...res_target_group.deepdata.tsqp_2563])]
        setData(tmp)
        setInitData(res_target_group.deepdata)
      }
    }
  }, []);

  // useEffect(async () => {
  //   let res_target_group = await api.getInitData('fullmap-tsqp')
  //   let tmp = [...new Set([...res_target_group.deepdata.tsqp_2562, ...res_target_group.deepdata.tsqp_2563])]
  //   setData(tmp)
  //   setInitData(res_target_group.deepdata)
  // }, [])

  useEffect(() => {
    if (initData) {
      // let resultSummaryAreaHeader = getSummaryAreaHeader(data)
      // let resultSummaryArea = getSummaryArea(data)
      // setSummaryAreaHeader(resultSummaryAreaHeader)
      let dataContext = [
        ...initData["tsqp_2563"],
        ...initData["tsqp_2562"],
      ];
      let dataContextFilter = [
        ...initData["tsqp_2563"],
        ...initData["tsqp_2562"],
      ];
      if (filter.generation !== "ทั้งหมด") {
        if (filter.generation === "1") {
          dataContext = initData["tsqp_2562"];
          dataContextFilter = initData["tsqp_2562"];
        } else if (filter.generation === "2") {
          dataContext = initData["tsqp_2563"];
          dataContextFilter = initData["tsqp_2563"];
        }
      }
      let dataContext_by_network = dataContext
      if (filter.network !== "ทั้งหมด") {
        dataContext_by_network = dataContext.filter(item => item.network === filter.network)
      }
      let response = onProcessData(
        {
          province: filter["province_name"],
          city: filter["city_name"],
          tumbon: filter["tumbon_name"],
          layer: filter.view,
        },
        convertMapContent(filter.view, dataContext_by_network)
      );
      if (filter.network !== "ทั้งหมด") {
        dataContextFilter = dataContextFilter.filter(
          (item) => item.network === filter.network
        );
      }
      if (!filter.province_name || filter.province_name !== "ทั้งหมด") {
        if (filter.city_name !== null) {
          dataContextFilter = [
            ...new Set(
              dataContextFilter.filter(
                (item) =>
                  item.province === filter.province_name &&
                  item.district === filter.city_name
              )
            ),
          ];
        } else {
          dataContextFilter = [
            ...new Set(
              dataContextFilter.filter(
                (item) => item.province === filter.province_name
              )
            ),
          ];
        }
      }
      console.log(response.data)
      setDataMap([
        { id: "border", layer: filter.view, data: response.data },
        {
          id: "school",
          layer: "marker",
          data:
            filter.sungkud === "ทั้งหมด" || filter.sungkud === "สพฐ."
              ? dataContextFilter
                .filter((x) => x.sungkud === "สพฐ.")
                .map((item) => ({ ...item, type: "school" }))
              : [],
          icon: marker_obec,
        },
        {
          id: "school2",
          layer: "marker",
          data:
            filter.sungkud === "ทั้งหมด" || filter.sungkud === "อปท."
              ? dataContextFilter
                .filter((x) => x.sungkud === "อปท.")
                .map((item) => ({ ...item, type: "school" }))
              : [],
          icon: marker_bpp,
        },
        {
          id: "school3",
          layer: "marker",
          data:
            filter.sungkud === "ทั้งหมด" || filter.sungkud === "สช."
              ? dataContextFilter
                .filter((x) => x.sungkud === "สช.")
                .map((item) => ({ ...item, type: "school" }))
              : [],
          icon: marker_onab,
        },
      ]);
      let data_legend = [
        // {
        //   name: "จำนวนนักเรียนที่ได้รับทุน",
        //   value: response.data.reduce((acc, cur) => acc + cur.student, 0),
        //   unit: "คน",
        // },
        {
          name: "จำนวนโรงเรียนที่ร่วมโครงการ",
          value: response.data.reduce((acc, cur) => acc + cur.school_tsqp, 0),
          unit: "โรงเรียน",
        },
      ];
      setLegendData({
        ...legend_data,
        title: filter.province_name,
        subtitle: filter.city_name,
        data: data_legend,
      });

    }
  }, [dataArea])

  const convertMapContent = (layer, data) => {
    let res = [];
    const province = [
      ...new Set(TumbonAddr.map((item) => item.provincename)),
    ].map((item) => {
      return {
        region: region.getRegion(item),
        province: item,
        // student: 0,
        school_tsqp: 0,
      };
    });
    const city = [
      ...new Set(
        TumbonAddr.map((item) => item.provincename + "_" + item.cityname)
      ),
    ].map((item) => {
      return {
        province: item.split("_")[0],
        city: item.split("_")[1],
        // student: 0,
        school_tsqp: 0,
      };
    });
    const tumbon = [
      ...new Set(
        TumbonAddr.map(
          (item) =>
            item.provincename + "_" + item.cityname + "_" + item.tumbonname
        )
      ),
    ].map((item) => {
      return {
        province: item.split("_")[0],
        city: item.split("_")[1],
        tumbon: item.split("_")[2],
        // student: 0,
        school_tsqp: 0,
      };
    });
    data.forEach((item) => {
      let student =
        item.total_kindergarten +
        item.total_junior +
        item.total_primary +
        item.total_high;
      let school = 1;
      if (layer === "country") {
        let index = province.findIndex((ele) => ele.province === item.province);
        if (index !== -1) {
          // !province[index]["student"]
          //   ? (province[index]["student"] = student)
          //   : (province[index]["student"] += student);
          !province[index]["school_tsqp"]
            ? (province[index]["school_tsqp"] = school)
            : (province[index]["school_tsqp"] += school);
        }
        res = province;
      } else if (layer === "province") {
        let index = city.findIndex(
          (ele) => ele.province === item.province && ele.city === item.district
        );
        if (index !== -1) {
          // !city[index]["student"]
          //   ? (city[index]["student"] = student)
          //   : (city[index]["student"] += student);
          !city[index]["school_tsqp"]
            ? (city[index]["school_tsqp"] = school)
            : (city[index]["school_tsqp"] += school);
        }
        res = city;
      } else if (layer === "city") {
        let index = tumbon.findIndex(
          (ele) =>
            ele.province === item.province &&
            ele.city === item.district &&
            ele.tumbon ===
            (item.province_id === "10"
              ? "แขวง" + item.subdistrict
              : item.subdistrict)
        );
        if (index !== -1) {
          // !tumbon[index]["student"]
          //   ? (tumbon[index]["student"] = student)
          //   : (tumbon[index]["student"] += student);
          !tumbon[index]["school_tsqp"]
            ? (tumbon[index]["school_tsqp"] = school)
            : (tumbon[index]["school_tsqp"] += school);
        }
        res = tumbon;
      } else if (layer === "tumbon") {
        let index = tumbon.findIndex(
          (ele) =>
            ele.province === item.province &&
            ele.city === item.district &&
            ele.tumbon ===
            (item.province_id === "10"
              ? "แขวง" + item.subdistrict
              : item.subdistrict)
        );
        if (index !== -1) {
          // !tumbon[index]["student"]
          //   ? (tumbon[index]["student"] = student)
          //   : (tumbon[index]["total"] += student);
          !tumbon[index]["school_tsqp"]
            ? (tumbon[index]["school_tsqp"] = school)
            : (tumbon[index]["school_tsqp"] += school);
        }
        res = tumbon;
      }
    });
    if (filter.region !== "ทั้งหมด" && filter.view === "country")
      res = res.filter((item) => item.region === filter.region);
    return res;
  };

  const onProcessData = (filter, data) => {
    const _genColorRange = (color_set, value) => {
      color_set = color_set.sort((a, b) => b.value - a.value);
      let color = "";
      for (let i in color_set) {
        if (value > color_set[i].value) {
          color = color_set[i].color;
          break;
        }
      }
      return color;
    };

    let result = [];
    data.forEach((element) => {
      if (
        filter.province &&
        filter.province !== "ทั้งหมด" &&
        !filter.city &&
        !filter.tumbon
      ) {
        if (filter.province === element.province) {
          result.push(element);
        }
      } else if (filter.province && filter.city && !filter.tumbon) {
        if (
          filter.province === element.province &&
          filter.city === element.city
        ) {
          result.push(element);
        }
      } else if (filter.province && filter.city && filter.tumbon) {
        if (
          filter.province === element.province &&
          filter.city === element.city &&
          filter.tumbon === element.tumbon
        ) {
          result.push(element);
        }
      } else {
        result.push(element);
      }
    });

    result.map((element) => {
      if (!isFinite(element["school_tsqp"] / element["school_tsqp"])) {
        element["percent"] = 0;
      } else {
        element["percent"] =
          (element["school_tsqp"] /
            result.map((item) => item.school_tsqp).reduce((a, b) => a + b, 0)) *
          100;
      }
      return element;
    });

    let data_color = [
      {
        color: border_color[0],
        value: Calculate.Percentile(
          90,
          result.map((item) => item.school_tsqp)
        ),
        count: 0,
        text: `มาก`,
      },
      {
        color: border_color[1],
        value: Calculate.Percentile(
          50,
          result.map((item) => item.school_tsqp)
        ),
        count: 0,
        text: `ปานกลาง`,
      },
      {
        color: border_color[2],
        value: Calculate.Percentile(
          10,
          result.map((item) => item.school_tsqp)
        ),
        count: 0,
        text: `ปานกลาง`,
      },
      { color: border_color[3], value: 0, count: 0, text: `น้อย` },
      { color: border_color[4], value: -100, count: 0, text: `น้อย` },
    ];

    result.forEach((element) => {
      element["color"] = _genColorRange(data_color, element.school_tsqp);
      // element['opacity'] = 0.5
      element["percent"] = parseFloat(element["percent"].toFixed(2));
    });

    let res = { data: result, notic: data_color };
    return res;
  };

  useEffect(() => {
    
    let tmpDataArea = data
    let tmpDataSummary = data
    let attr = ['generation', 'network', 'sungkud', 'province_name']

    attr.forEach(key => {
      if (key === 'province_name') {
        if (filter[key] !== 'ทั้งหมด') {
          tmpDataArea = tmpDataArea.filter(item => item.province === filter[key])
        }
      } else {
        if (filter[key] !== 'ทั้งหมด') {
          tmpDataArea = tmpDataArea.filter(item => item[key] === filter[key])
        }
      }


    });

    let resultSummaryArea = getSummaryArea(tmpDataArea)
    setSummaryArea(resultSummaryArea)
    setDataArea(tmpDataArea)

    if (filter.network !== 'ทั้งหมด') {
      tmpDataSummary = tmpDataSummary.filter(item => item.network === filter.network)
    }

    let tmpSummaryTarget = getSummaryTarget(tmpDataSummary)
    let tmpSummarySungkud = getSummarySungkud(tmpDataSummary)
    let tmpSummaryClass = getSummaryClass(tmpDataSummary)
    let tmpSummaryCCTSungkud = getSummaryCCTSungkudChart(tmpDataSummary)

    setSummaryTarget(tmpSummaryTarget)
    setSummarySungkud(tmpSummarySungkud)
    setSummaryClass(tmpSummaryClass)
    setSummaryCCTSungkud(tmpSummaryCCTSungkud)
  }, [data, filter, initData])

  const handleChangeTab = (event, newValue, pretab) => {
    if (newValue === 0) {
      if (filter.view !== 'country' || filter.network !== 'ทั้งหมด' | filter.generation !== 'ทั้งหมด') {
        setFilter({
          view: filter.minView,
          year: "2565",
          region: "ทั้งหมด",
          province_name: filter.minView !== "province" ? "ทั้งหมด" : filter.province_name,
          city_name: null,
          tumbon_name: null,
          generation: 'ทั้งหมด',
          network: 'ทั้งหมด',
          network_summary: 'ทั้งหมด',
          sungkud: 'ทั้งหมด',
        })
      }
    }
    setTabValue(newValue)

  };

  const onclickBorder = (layer, filterBorder) => {
    if (layer === "tumbon") return;
    if (!is_Click.current) {
      is_Click.current = true;
      let _filter = { ...filter };
      if (layer === "province" && filterBorder.province) {
        _filter["view"] = "province";
        _filter["year"] = filterBorder.year;
        _filter["province_name"] = filterBorder.province;
        _filter["city_name"] = null;
        _filter["tumbon_name"] = null;
      } else if (layer === "city" && filterBorder.city) {
        _filter["view"] = "city";
        _filter["year"] = filterBorder.year;
        _filter["province_name"] = filterBorder.province;
        _filter["city_name"] = filterBorder.city;
        _filter["tumbon_name"] = null;
      }
      setFilter(_filter);
      setTimeout(() => {
        is_Click.current = false;
      }, 1000);
    }
  };

  const onBack = () => {
    let _filter = { ...filter }
    if (_filter.view === _filter.minView) return;
    if (_filter.view === 'province') {
      _filter['view'] = 'country'
      _filter['province_name'] = 'ทั้งหมด'
      _filter['city_name'] = null
      _filter['tumbon_name'] = null
    } else if (_filter.view === 'city') {
      _filter['view'] = 'province'
      _filter['city_name'] = null
      _filter['tumbon_name'] = null
    }
    setFilter(_filter)
  }

  const onClickMarker = (property) => {
    if (!is_Click.current) {
      is_Click.current = true
      setModalSchool({
        visible: true,
        resource: 'school',
        ...property
      })
      setTimeout(() => {
        is_Click.current = false
      }, 1000)
    }
  }

  let tab_content = [
    {
      title: 'ภาพรวม',
      content: <SummaryTSQP mainData={data} summaryArea={summaryArea} summaryTarget={summaryTarget} summarySungkud={summarySungkud} summaryClass={summaryClass} summaryCCTSungkud={summaryCCTSungkud} filter={filter} setFilter={setFilter} />
    },
    {
      title: 'ดูตามพื้นที่',
      content: <AreaTSQP mainData={data} dataArea={dataArea} summaryArea={summaryArea} filter={filter} onClickSchoolCard={onClickMarker} setFilter={setFilter} />
    },

  ]

  let sum_exjon = (modalSchool?.h_exjon_f ? modalSchool?.h_exjon_f : 0) +
    (modalSchool?.h_exjon_m ? modalSchool?.h_exjon_m : 0) +
    (modalSchool?.k_exjon_f ? modalSchool?.k_exjon_f : 0) +
    (modalSchool?.k_exjon_m ? modalSchool?.k_exjon_m : 0) +
    (modalSchool?.m_exjon_f ? modalSchool?.m_exjon_f : 0) +
    (modalSchool?.m_exjon_m ? modalSchool?.m_exjon_m : 0) +
    (modalSchool?.p_exjon_f ? modalSchool?.p_exjon_f : 0) +
    (modalSchool?.p_exjon_m ? modalSchool?.p_exjon_m : 0)

  return (
    <Layoutas isHideFooter Role={['partner', 'eef', 'partner_province']}>
      <Modal
        width={'1000px'}
        bodyStyle={{ borderRadius: 24 }}
        title={null}
        okButtonProps={{ style: { display: 'none' } }}
        cancelButtonProps={{ style: { display: 'none' } }}
        visible={modalSchool.visible}
        centered={true}
        CancelText={'ปิด'}
        onCancel={() => {
          setModalSchool({ visible: false })
        }}
      >
        <div className='text-3xl'>โรงเรียน{modalSchool?.hostname} <span className='text-xl'></span></div>
        <div className='text-xl'>ที่อยู่ ตำบล{modalSchool?.subdistrict} อำเภอ{modalSchool?.district} จังหวัด{modalSchool?.province}</div>
        <Divider style={{ margin: 8 }} />
        <div className='text-3xl m-2'>ได้รับทุนจากโครงการทุนเสมอภาค {sum_exjon} คน</div>
        <Divider style={{ margin: 4 }} />

        <div className='grid grid-cols-12 gap-4 '>
          <div className='col-span-12 lg:col-span-6 flex-col'>
            <div className='relative w-full '>
              <div className='w-full flex flex-col rounded-lg p-2'>
                <div className='text-xl text-green-500 '>ข้อมูลพื้นฐาน</div>
                <div className='text-lg '>สังกัด :  {modalSchool?.sungkud} </div>
                <div className='text-lg '>ขนาดโรงเรียน :  {modalSchool?.school_size ? modalSchool?.school_size : '-'} </div>
                <div className='text-lg '>ภูมิภาค :  {modalSchool?.region_thai ? modalSchool?.region_thai : '-'} </div>
                {/* <div className='text-lg '>ลักษณะภูมิประเทศ :  {MAP_TEXT[modalSchool?.geographic_location]} </div> */}
                {/* <div className='text-lg '>ระดับชั้นที่เปิดสอน :  {modalSchool?.lowest_classes} - {modalSchool?.highest_classes}</div> */}
                <Divider style={{ margin: 4 }} />
                <div className='text-xl text-green-500 mt-8'>ข้อมูลนักเรียน</div>
                <div className='text-lg '>จำนวนนักเรียนทั้งหมด :   {(modalSchool?.total_kindergarten + modalSchool?.total_primary + modalSchool?.total_junior + modalSchool?.total_high)?.toLocaleString("en-US")} คน </div>
                <div className='text-lg '>อนุบาล :  {modalSchool?.total_kindergarten?.toLocaleString("en-US")} คน</div>
                <div className='text-lg '>ประถมศึกษา : {modalSchool?.total_primary?.toLocaleString("en-US")}  คน</div>
                <div className='text-lg '>มัธยมศึกษาตอนต้น :  {modalSchool?.total_junior?.toLocaleString("en-US")} คน</div>
                <div className='text-lg '>มัธยมศึกษาตอนปลาย :  {modalSchool?.total_high?.toLocaleString("en-US")} คน</div>
              </div>
            </div>
          </div>
          <div className='col-span-12 lg:col-span-6 flex-col'>
            <div className='relative w-full '>
              <div className='w-full flex flex-col rounded-lg p-2'>
                <div className='text-xl text-green-500 '>ข้อมูลครู</div>
                <div className='text-lg '>จำนวนครูทั้งหมด :  {modalSchool?.t_total ? modalSchool?.t_total?.toLocaleString("en-US") : '-'} คน</div>
                <div className='text-lg '>จำนวนครูผู้ชาย :  {modalSchool?.t_total_m ? modalSchool?.t_total_m?.toLocaleString("en-US") : '-'} คน</div>
                <div className='text-lg '>จำนวนครูผู้หญิง :  {modalSchool?.t_total_f ? modalSchool?.t_total_f?.toLocaleString("en-US") : '-'} คน</div>
                {/* <div className='text-lg '>ไฟฟ้า :  {modalSchool?.school_has_electricity ? 'โรงเรียนมีไฟฟ้าใช้' : 'โรงเรียนไม่มีไฟฟ้าใช้'}  </div>
                <div className='text-lg '>แหล่งน้ำ :  {modalSchool?.water_source} </div> */}
                <Divider style={{ margin: 4 }} />
                <div className='text-xl text-green-500 mt-8'>ข้อมูลทรัพยากร</div>
                <div className='text-lg '>ไฟฟ้า :  {modalSchool?.electricity ? 'มีไฟฟ้าใช้' : 'ไม่มีไฟฟ้าใช้'}  </div>
                <div className='text-lg '>แหล่งน้ำ :  {modalSchool ? modalSchool?.water_source : '-'} </div>
              </div>
            </div>
          </div>
          <div className='col-span-12 lg:col-span-6 flex-col'>
            <div className='relative w-full '>
              <div className='w-full flex flex-col rounded-lg p-2'>
                <Divider style={{ margin: 4 }} />
                <div className='text-xl text-green-500 mt-8'>ข้อมูลติดต่อ</div>
                <div className='text-lg '>email :  {modalSchool?.email ? modalSchool?.email : '-'}  </div>
                <div className='text-lg '>website :  {modalSchool?.website ? modalSchool?.website : '-'} </div>
                <a target="_blank" href={`https://www.google.com/maps/search/?api=1&query=${modalSchool?.lat},${modalSchool?.lng}`} className='text-lg '>นำทางไปยัง Google Maps</a>
              </div>
            </div>
          </div>
        </div>

      </Modal>
      <div className='relative overflow-hidden'>
        <div className='w-[100vw] h-[100vh]'>
          <Mapbox
            id={'map-tsqp'}
            filter={filter}
            data={data_map}
            className="fullmap-cct-container"
            style={"border_basic"}
            openSideTab={open}
            // notic_title={'จำนวนโรงเรียนในประเทศไทย'}
            onclickBorder={onclickBorder}
            onClickMarker={onClickMarker}
          />

          <div className='absolute top-[90px] left-2 z-1'>
            <LegendCard {...legend_data} />
            <div className='justify-left mt-2 mx-auto'>
              {filter.view !== filter.minView ? <button class="bg-[#fb923c] hover:bg-[#f97316] border-8 border-[#ffedd5] text-white font-bold p-2 rounded-full" onClick={() => onBack()}>
                <ArrowBackIcon sx={{ fontSize: 18, color: 'white' }} /> ย้อนกลับ
              </button> : <></>}
            </div>
          </div>

          <div className='absolute bottom-[50px] md:bottom-[10px] left-[100px] z-1 flex'>
            <div className='h-full rounded-lg flex flex-col shadow-xl bg-white px-2 py-3'>
              <span className='font-bold center mx-1 mb-2'>สัดส่วนนักเรียนที่ได้รับทุน</span>
              <div className='flex'>
                <div className='flex flex-col'>
                  <div class={`h-3 w-8 md:w-16 bg-[#1b5e20]`}>
                  </div>
                  <div class="font-bold text-left">
                    มาก
                  </div>
                </div>
                <div className='flex flex-col'>
                  <div class={`h-3 w-8 md:w-16 bg-[#388e3c]`}>
                  </div>
                </div>
                <div className='flex flex-col'>
                  <div class={`h-3 w-8 md:w-16 bg-[#66bb6a]`}>
                  </div>
                </div>
                <div className='flex flex-col'>
                  <div class={`h-3 w-8 md:w-16 bg-[#a5d6a7]`}>
                  </div>
                  <div class="font-bold text-right">
                    น้อย
                  </div>
                </div>
                <div className='ml-4  flex flex-col'>
                  <div class={`h-3 w-8 md:w-16 bg-[#94a3b8]`}>
                  </div>
                  <div class="font-bold text-center">
                    ไม่มีข้อมูล
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='absolute bottom-[10px] left-[470px] z-1'>
            <div className='w-[250px] h-full rounded-lg flex flex-col shadow-xl bg-white px-2 py-3'>
              <span className='font-bold center mx-1 mb-2'>ประเภทสถานศึกษาตามสังกัด</span>
              <div class="flex flex-row">
                <div class="flex flex-row text-center mr-2">
                  <img src={marker_obec} style={{ width: '20px', height: '20px', margin: 'auto' }} />
                  <span className='center m-auto'>สพฐ.</span>
                </div>
                <div class="flex flex-row text-center mr-2">
                  <img src={marker_bpp} style={{ width: '20px', height: '20px', margin: 'auto' }} />
                  <span className='center m-auto'>อปท.</span>
                </div>
                <div class="flex flex-row text-center mr-2">
                  <img src={marker_onab} style={{ width: '20px', height: '20px', margin: 'auto' }} />
                  <span className='center m-auto'>สช.</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Sidetab
          title="โครงการพัฒนาครูและโรงเรียนเพื่อยกระดับคุณภาพการ ศึกษาอย่างต่อเนื่อง (TSQP)"
          tab={tab_content}
          open={open}
          tabvalue={tabvalue}
          setOpen={setOpen}
          handleChangeTab={handleChangeTab}
          titleRef="*โครงการ TSQP ข้อมูลจำนวนโรงเรียนที่เข้าร่วมโครงการ ปีการศึกษา 2564"
          subjectTitleRef="*ประมวลผล ณ เดือนเมษายน 2565"
        />
      </div>
    </Layoutas>
  )



}

export default FullMap
